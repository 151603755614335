<template>
  <div class="mb-6">
    <h2
      v-if="!editMode"
      class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200"
    >
      Create new Category
    </h2>
    <h2
      v-else
      class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200"
    >
      Edit Category
    </h2>
    <div
      class="w-full md:w-3/4 shadow-lg bg-white dark:bg-gray-700 dark:border-gray-500 p-4 md:p-8 rounded-xl flex flex-col space-y-4 border border-gray-200"
    >
      <div class="w-full">
        <label class="">
          <span class="label">Name</span>
          <input
            type="text"
            class="input"
            placeholder="Name"
            v-model="$v.category.name.$model"
          />
        </label>
      </div>
      <div class="w-full">
        <label class="">
          <span class="label">Category belongs to which cafe</span>
          <select v-model="$v.category.cafe_id.$model" class="input">
            <option value="" selected>Select Categorie's cafe</option>
            <option v-for="cafe in cafes" :key="cafe.id" :value="cafe.id">
              {{ cafe.name }}
            </option>
          </select>
        </label>
      </div>
      <div class="w-full flex justify-center">
        <button
          class="btn w-44"
          :class="$v.$invalid ? 'bg-gray-600 text-white' : null"
          @click="editMode ? updateCategory() : saveCategory()"
        >
          {{ editMode ? "Update Category" : "Create Category" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import isNil from "ramda/es/isNil";
export default {
  name: "CreateCategoryComponent",
  mixins: [validationMixin],
  data() {
    return {
      category: {
        name: "",
        cafe_id: "",
      },
    };
  },
  mounted() {
    this.loadCafes();
    if (this.editMode) {
      this.loadCategory();
    }
  },
  computed: {
    ...mapGetters({
      cafes: ["cafe/getCafes"],
      cat: ["category/getCategory"],
    }),
    editMode() {
      return !isNil(this.$route.params) && !isNil(this.$route.params.nano_id);
    },
  },
  validations: {
    category: {
      name: {
        required,
      },

      cafe_id: {
        required,
      },
    },
  },
  methods: {
    loadCafes() {
      this.$store.dispatch("cafe/getCafes");
    },
    loadCategory() {
      this.$store.dispatch("category/loadCategory", {
        nano_id: this.$route.params.nano_id,
      });
    },
    saveCategory() {
      if (!this.$v.$invalid) {
        this.$store.dispatch("category/storeCategory", {
          category: this.category,
        });
      }
    },
    updateCategory() {
      if (!this.$v.$invalid) {
        this.$store.dispatch("category/updateCategory", {
          nano_id: this.$route.params.nano_id,
          category: this.category,
        });
      }
    },
  },
  watch: {
    cat: {
      handler() {
        if (!isNil(this.cat) && this.editMode) {
          this.category = {
            ...this.cat,
            cafe_id: this.cat.cafe ? this.cat.cafe.id : "",
          };
        }
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
